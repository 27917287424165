<template>
  <div>
    <Breadcrumbs />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              {{ $t("Icon") }}
            </th>
            <th class="text-left">
              {{ $t("Identity Name") }}
            </th>
            <th class="text-left">
              {{ $t("Authentication Adapter") }}
            </th>
            <th class="text-left">
              {{ $t("Description") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.type" class="text-left">
            <td class="pl-4">
              <v-img
                :src="require('@/assets/sources/' + item.type + '.png')"
                max-width="40"
              ></v-img>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.description }}</td>
            <td class="text-center">
              <v-btn
                elevation="0"
                color="primary"
                outlined
                @click.stop="show_sheet(item)"
              >
                {{ $t("source.Create Identity Source") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component :is="sheet_editor" type="create" />
  </div>
</template>

<script>
import CasEditor from "@/views/source/editor/Cas";
import Oauth2Editor from "@/views/source/editor/Oauth2";
import Breadcrumbs from "@/components/Breadcrumbs";
import LdapEditor from "@/views/source/editor/Ldap";
import FNU_LdapEditor from "@/views/source/editor/FNU_Ldap";
import HttpBasicEditor from "@/views/source/editor/HttpBasic";
import CasRestAPI from "./editor/CasRestAPI";
import WechatEnterpriseEditor from "@/views/source/editor/WechatEnterprise";
import DingTalkEditor from "@/views/source/editor/DingTalk";
import LarkEditor from "@/views/source/editor/Lark";
import RadiusEditor from "@/views/source/editor/Radius";
import WebVPNEditor from "@/views/source/editor/WebVPN";
import { mapMutations } from "vuex";

const EDITORS = {
  CAS: CasEditor,
  LDAP: LdapEditor,
  FNU_LDAP: FNU_LdapEditor,
  OAUTH2: Oauth2Editor,
  HTTP_BASIC: HttpBasicEditor,
  WECHAT_ENTERPRISE: WechatEnterpriseEditor,
  CAS_REST_API: CasRestAPI,
  DING_TALK: DingTalkEditor,
  LARK: LarkEditor,
  RADIUS: RadiusEditor,
  WEBVPN: WebVPNEditor
};

export default {
  name: "CreateSource",
  data() {
    return {
      show: true,
      sheet_editor: null,
      sources: [
        {
          name: "CAS身份源",
          type: "CAS",
          description: "使用CAS 2.0/3.0登录"
        },
        {
          name: "CAS_REST_API身份源",
          type: "CAS_REST_API",
          description: "使用 CAS REST API 进行认证"
        },
        {
          name: "OAuth2认证源",
          type: "OAUTH2",
          description: "使用OAuth 2.0登录"
        },
        {
          name: "企业微信身份源",
          type: "WECHAT_ENTERPRISE",
          description: "使用企业微信微应用登录"
        },
        {
          name: "LDAP身份源",
          type: "LDAP",
          description: "使用LDAP(如AD域)进行认证"
        },
        {
          name: "FNU_LDAP身份源",
          type: "FNU_LDAP",
          description: "使用FNU_LDAP(如AD域)进行认证"
        },
        {
          name: "Radius认证源",
          type: "RADIUS",
          description: "使用Radius方式进行认证"
        },
        {
          name: "HTTP Basic认证源",
          type: "HTTP_BASIC",
          description: "使用HTTP Basic方式进行认证"
        },
        {
          name: "钉钉身份源",
          type: "DING_TALK",
          description: "使用 DING_TALK 进行认证"
        },
        {
          name: "飞书身份源",
          type: "LARK",
          description: "使用 LARK 进行认证"
        },
        {
          name: "WebVPN身份源",
          type: "WEBVPN",
          description: "使用 WebVPN 进行认证"
        }
      ]
    };
  },
  created() {
    this.change_breadcrumbs([
      { text: "Identity Source" },
      { text: "Create Identity Source" }
    ]);
  },
  methods: {
    ...mapMutations(["change_breadcrumbs"]),
    show_sheet(item) {
      this.sheet_editor = EDITORS[item.type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  components: {
    CasEditor,
    Oauth2Editor,
    Breadcrumbs,
    LdapEditor,
    FNU_LdapEditor,
    HttpBasicEditor,
    WechatEnterpriseEditor,
    DingTalkEditor,
    LarkEditor,
    RadiusEditor
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
